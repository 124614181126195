<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, /*LTooltip,*/ LIcon } from "vue2-leaflet";

export default {
  data() {
    return {
      componentKey: 0,
      //latitude: 0,
      //longitude: 0,
      zoom: 10,
      center: latLng(31.862776627976007, -116.60249948540894),
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:'',
      withTooltip: latLng(0.0, 0.0),
      showParagraph: false,
      staticAnchor: [16, 37],
      mapOptions: {
        zoomSnap: 0.5
      },
      markers: [
        {
          id: "m1",
          position: { lat: 31.862776627976007, lng: -116.60249948540894 },
          draggable: true,
          visible: true
        },
      ],
      showMap: true
    };
  },
  props:{
    lat: [Number, String],
    lng: [Number, String],
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    //LTooltip,
    LIcon
  },
  
  computed:{
    latitude:{
      get: function(){
        return this.lat;
      },
      set: function(newLat){
        this.$emit('update:lat', newLat);
        this.$forceUpdate();
      }
    },
    longitude:{
      get: function(){
        return this.lng;
      },
      set: function(newLng){
        this.$emit('update:lng', newLng);
        this.$forceUpdate();
      }
    }
  },
  
  
  watch:{
    lat(newLat, oldLat){
      if(newLat != oldLat){
        this.latitude = parseFloat(newLat);
        this.markers[0].position.lat = this.lat;
        this.center   = latLng(this.latitude, this.longitude);
        this.zoom = 25;
        this.$forceUpdate();
      }
    },
    lng(newLng, oldLng){
      if(newLng != oldLng){
        this.longitude = parseFloat(newLng);
        this.markers[0].position.lng = this.lng;
        this.center   = latLng(this.latitude, this.longitude);
        this.zoom = 25;
        this.$forceUpdate();
      }
    }
  },
  
  mounted: function(){
    

  },
  methods: {
    innerClick() {
      alert("Click!");
    },

    addMarker(event) {
      //console.log(event.latlng);
      //this.markers.push(event.latlng);
      this.markers[0].position.lat = event.latlng.lat;
      this.markers[0].position.lng = event.latlng.lng;

      this.latitude = parseFloat(event.latlng.lat);
      this.longitude = parseFloat(event.latlng.lng);

      this.$emit(
        'changeMarker',
        {
          lat: this.latitude,
          lng: this.longitude
        }
      );
    },

    forceRerenderMap() {
      this.componentKey += 1;
      this.$forceUpdate();
    }
  }
};
</script>

<template :key="componentKey">
  <l-map style="height: 400px; width: 100%" :zoom="zoom" :center="center" @click="addMarker">
    <l-tile-layer :url="url" :attribution="attribution" />
    <l-marker
      v-for="marker in markers"
      :key="marker.id"
      :visible="marker.visible"
      :draggable="marker.draggable"
      :lat-lng="marker.position"
    >
      <l-icon :icon-anchor="staticAnchor">
        <img src="@/assets/images/leaflet/marker-icon.png" />
      </l-icon>
    </l-marker>
  </l-map>
</template>